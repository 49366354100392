"use strict";

exports.__esModule = true;
exports.default = void 0;

var PropTypes = _interopRequireWildcard(require("prop-types"));

var _react = _interopRequireDefault(require("react"));

var _Listbox = _interopRequireDefault(require("./Listbox"));

var _ListOption = _interopRequireDefault(require("./ListOption"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

var propTypes = {
  searchTerm: PropTypes.string,
  focused: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  activeId: PropTypes.string
};

function AddToListOption(_ref) {
  var searchTerm = _ref.searchTerm,
      onSelect = _ref.onSelect,
      focused = _ref.focused,
      children = _ref.children,
      activeId = _ref.activeId,
      props = _objectWithoutProperties(_ref, ["searchTerm", "onSelect", "focused", "children", "activeId"]);

  return _react.default.createElement(_Listbox.default, _extends({}, props, {
    className: "rw-list-option-create"
  }), _react.default.createElement(_ListOption.default, {
    onSelect: onSelect,
    focused: focused,
    activeId: activeId,
    dataItem: searchTerm
  }, children));
}

AddToListOption.propTypes = propTypes;
var _default = AddToListOption;
exports.default = _default;
module.exports = exports["default"];