"use strict";

exports.__esModule = true;
exports.default = makeAutoFocusable;
exports.PropTypes = void 0;

var _propTypes = require("prop-types");

var _reactDom = require("react-dom");

var _spyOnComponent = _interopRequireDefault(require("spy-on-component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PropTypes = {
  autoFocus: _propTypes.bool
};
exports.PropTypes = PropTypes;

function makeAutoFocusable(instance) {
  (0, _spyOnComponent.default)(instance, {
    componentDidMount: function componentDidMount() {
      var autoFocus = this.props.autoFocus;
      if (autoFocus) this.focus ? this.focus() : (0, _reactDom.findDOMNode)(this).focus();
    }
  });
}