"use strict";

exports.__esModule = true;
exports.dataIndexOf = dataIndexOf;
exports.valueMatcher = valueMatcher;
exports.dataItem = dataItem;
exports.dataText = exports.dataValue = void 0;

var _ = require("./_");

var dataValue = function dataValue(data, field) {
  var value = data;
  if (typeof field === 'function') value = field(data);else if (data == null) value = data;else if (typeof field === 'string' && typeof data === 'object' && field in data) value = data[field];
  return value;
};

exports.dataValue = dataValue;

var dataText = function dataText(item, textField) {
  var value = dataValue(item, textField);
  return value == null ? '' : value + '';
};

exports.dataText = dataText;

function dataIndexOf(data, item, valueField) {
  var idx = -1;

  var isValueEqual = function isValueEqual(datum) {
    return valueMatcher(item, datum, valueField);
  };

  while (++idx < data.length) {
    var datum = data[idx];
    if (datum === item || isValueEqual(datum)) return idx;
  }

  return -1;
}
/**
 * I don't know that the shallow equal makes sense here but am too afraid to
 * remove it.
 */


function valueMatcher(a, b, valueField) {
  return (0, _.isShallowEqual)(dataValue(a, valueField), dataValue(b, valueField));
}

function dataItem(data, item, valueField) {
  var idx = dataIndexOf(data, item, valueField);
  return idx !== -1 ? data[idx] : item;
}