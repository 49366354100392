// This rule doesn't play well with namespaced exports

/* eslint-disable import/export */
export { default as VictoryAccessibleGroup } from "./victory-accessible-group/victory-accessible-group";
export { default as VictoryAnimation } from "./victory-animation/victory-animation";
export { default as VictoryContainer } from "./victory-container/victory-container";
export { default as VictoryLabel } from "./victory-label/victory-label";
export { default as VictoryTransition } from "./victory-transition/victory-transition";
export { default as VictoryClipContainer } from "./victory-clip-container/victory-clip-container";
export { default as VictoryTheme } from "./victory-theme/victory-theme";
export { default as VictoryPortal } from "./victory-portal/victory-portal";
export { default as Portal } from "./victory-portal/portal";
export { default as Arc } from "./victory-primitives/arc";
export { default as Background } from "./victory-primitives/background";
export { default as Border, default as Box } from "./victory-primitives/border";
export { default as Circle } from "./victory-primitives/circle";
export { default as ClipPath } from "./victory-primitives/clip-path";
export { default as Line } from "./victory-primitives/line";
export { default as LineSegment } from "./victory-primitives/line-segment";
export { default as Path } from "./victory-primitives/path";
export { default as Point } from "./victory-primitives/point";
export { default as Rect } from "./victory-primitives/rect";
export { default as Text } from "./victory-primitives/text";
export { default as TSpan } from "./victory-primitives/tspan";
export { default as Whisker } from "./victory-primitives/whisker";
export { default as addEvents } from "./victory-util/add-events";
import * as _Collection from "./victory-util/collection";
export { _Collection as Collection };
import * as _Data from "./victory-util/data";
export { _Data as Data };
import * as _DefaultTransitions from "./victory-util/default-transitions";
export { _DefaultTransitions as DefaultTransitions };
import * as _Domain from "./victory-util/domain";
export { _Domain as Domain };
import * as _Events from "./victory-util/events";
export { _Events as Events };
import * as _Helpers from "./victory-util/helpers";
export { _Helpers as Helpers };
import * as _Immutable from "./victory-util/immutable";
export { _Immutable as Immutable };
import * as _LabelHelpers from "./victory-util/label-helpers";
export { _LabelHelpers as LabelHelpers };
import * as _Log from "./victory-util/log";
export { _Log as Log };
import * as _PropTypes from "./victory-util/prop-types";
export { _PropTypes as PropTypes };
import * as _Scale from "./victory-util/scale";
export { _Scale as Scale };
import * as _Selection from "./victory-util/selection";
export { _Selection as Selection };
import * as _Style from "./victory-util/style";
export { _Style as Style };
import * as _TextSize from "./victory-util/textsize";
export { _TextSize as TextSize };
export { default as Timer } from "./victory-util/timer";
import * as _Transitions from "./victory-util/transitions";
export { _Transitions as Transitions };
import * as _CommonProps from "./victory-util/common-props";
export { _CommonProps as CommonProps };
export { default as Wrapper } from "./victory-util/wrapper";
import * as _Axis from "./victory-util/axis";
export { _Axis as Axis };
export { default as TimerContext } from "./victory-util/timer-context";
export { default as PortalContext } from "./victory-portal/portal-context";