"use strict";

exports.__esModule = true;
exports.default = createTimeoutManager;

var _spyOnComponent = _interopRequireDefault(require("spy-on-component"));

var _mountManager = _interopRequireDefault(require("./mountManager"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function createTimeoutManager(componentInstance) {
  var isMounted = (0, _mountManager.default)(componentInstance);
  var timers = Object.create(null);
  var manager;
  (0, _spyOnComponent.default)(componentInstance, {
    componentWillUnmount: function componentWillUnmount() {
      for (var k in timers) {
        clearTimeout(timers[k]);
      }

      timers = null;
    }
  });
  return manager = {
    clear: function clear(key) {
      clearTimeout(timers[key]);
    },
    set: function set(key, fn, ms) {
      if (!isMounted()) return;
      manager.clear(key);
      timers[key] = setTimeout(fn, ms);
    }
  };
}

module.exports = exports["default"];