"use strict";

exports.__esModule = true;
exports.default = spyOnMount;

var _spyOnComponent = _interopRequireDefault(require("spy-on-component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function spyOnMount(componentInstance) {
  var mounted = true;
  (0, _spyOnComponent.default)(componentInstance, {
    componentWillUnmount: function componentWillUnmount() {
      mounted = false;
    }
  });
  return function () {
    return mounted;
  };
}

module.exports = exports["default"];